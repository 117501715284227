import React from "react";
import { auth } from "./firebaseSetup";

export type userData = {
  email: string;
  actualRole: string;
  assumedRole: string;
  claims: Record<string, any>;
  token: string;
};

const ctx = React.createContext<{ user?: userData }>({});

export const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = React.useState<userData>();

  React.useEffect(() => {
    const unsubscribeAuth = auth.onAuthStateChanged(async (authUser) => {
      if (!authUser) {
        if (user) {
          setUser(undefined);
        }
        return;
      }
      if (authUser.email === user?.email) {
        return;
      }

      if (!authUser.email) {
        if (user) {
          setUser(undefined);
        }
        return;
      }

      // TODO: Clean this up to make it easier to maintain. Go enough for now, only impacts the docs website.
      if (
        !authUser.email.endsWith("@immertec.com") &&
        authUser.email !== "manolet.balanay@gmail.com" && // Techdogs contractor working on the Headset Requests API.
        authUser.email !== "jen_ore@yahoo.com" // Techdogs contractor working on the Headset Requests API.
      ) {
        alert("Please use a valid corporate email.");
        setUser(undefined);
        auth.signOut();
        return;
      }

      const data = await authUser.getIdTokenResult();

      setUser({
        email: authUser.email,
        actualRole: data.claims.role,
        assumedRole: data.claims.role, // TODO.
        claims: data.claims,
        token: data.token,
      });
    });

    return unsubscribeAuth;
  }, [user, setUser]);

  return <ctx.Provider value={{ user }}>{children}</ctx.Provider>;
};

export const useAuthContext = (): { user?: userData } => React.useContext(ctx);
