import React from "react";

import "rapidoc"; // Load RapiDoc. Doesn't support TypeScript.

import openapi from "./openapi.yaml";
import { useAuthContext } from "./AuthProvider";

const getApiUrl = () => {
  switch (process.env.REACT_APP_ENVIRONMENT) {
    case "gold":
      return "https://api.immertec.gold";
    case "production":
      return "https://api.immertec.com";
    default:
      return "https://api.immertec.red";
  }
};

export const RapiDoc = () => {
  const ref = React.useRef();
  const { user } = useAuthContext();

  if (!user?.token) return null;

  return (
    <rapi-doc
      id="the-doc"
      ref={ref}
      spec-url={openapi}
      render-style="focused"
      show-header={false}
      allow-authentication={false}
      allow-server-selection={false}
      allow-spec-url-load={false}
      allow-spec-file-load={false}
      theme="light"
      primary-color="#3b6cb3"
      bg-color="#F6F7FB"
      header-color="#FFFFFF"
      nav-bg-color="#253747"
      use-path-in-nav-bar={false}
      sort-endpoints-by="summary"
      schema-description-expanded={true}
      schema-style="tree"
      default-schema-tab="model"
      default-api-server={getApiUrl()}
      api-key-name="Authorization"
      api-key-location="header"
      api-key-value={`Bearer ${user.token}`}
    />
  );
};
