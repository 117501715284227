import React from "react";

import { Button, Col, Container, Form, Navbar } from "react-bootstrap";
import { auth, googleProvider } from "./firebaseSetup";

import "bootstrap/dist/css/bootstrap.min.css";

import { RapiDoc } from "./RapiDoc";
import { useAuthContext } from "./AuthProvider";

export const App: React.FC = () => {
  const { user } = useAuthContext();

  const handleLogout = React.useCallback(async () => {
    try {
      await auth.signOut();
    } catch (error) {
      console.error("Logout error:", error);
    }
  }, []);

  return (
    <React.Fragment>
      <Navbar className="justify-content-between px-4">
        <Navbar.Brand>
          <img slot="logo" alt="logo" src={`/images/logo-${process.env.REACT_APP_ENVIRONMENT}.png`} />
        </Navbar.Brand>
        {user && (
          <Navbar.Text>
            {user.email} ({user.claims.name})
          </Navbar.Text>
        )}
        {user && (
          <Button className="btn-secondary" onClick={handleLogout}>
            Logout
          </Button>
        )}
      </Navbar>
      {user ? <RapiDoc /> : <SignInForm />}
    </React.Fragment>
  );
};

const SignInForm: React.FC = () => {
  const emailRef = React.useRef<HTMLInputElement>(null);
  const passwordRef = React.useRef<HTMLInputElement>(null);

  const handleLogin = React.useCallback(async () => {
    try {
      await auth.signInWithEmailAndPassword(emailRef.current!.value, passwordRef.current!.value);
    } catch (error) {
      console.error("Login error:", error);
    }
  }, []);

  const handleGCPLogin = React.useCallback(async () => {
    try {
      await auth.signInWithPopup(googleProvider);
    } catch (error) {
      console.error("Login error:", error);
    }
  }, []);

  return (
    <Container style={{ maxWidth: "500px" }} fluid>
      <Form className="mt-4">
        <Form.Group controlId="formEmail">
          <Form.Label>Email</Form.Label>
          <Form.Control ref={emailRef} type="email" placeholder="email" />
        </Form.Group>
        <Form.Group controlId="formPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control ref={passwordRef} type="password" placeholder="password" />
        </Form.Group>
        <Form.Row>
          <Col xs={6}>
            <Button onClick={handleLogin} type="button" variant="secondary" block>
              Sign In
            </Button>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col xs={6}>
            <Button onClick={handleGCPLogin} type="button" variant="secondary" block>
              Sign In with Google
            </Button>
          </Col>
        </Form.Row>
      </Form>
    </Container>
  );
};
